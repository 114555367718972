import React from "react"
import { graphql } from "gatsby"

import { Homepage as Page } from "@components/Homepage/Homepage"

export const query = graphql`
  query {
    page: sanityPageHomepage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
